import { useFetcher } from '@remix-run/react'
import { useEffect } from 'react'

import { useToast } from '~/components/Toast'
import type { ButtonProps } from '~/components/Button'
import Button from '~/components/Button'
import type { ActionResponse } from './action'

interface ResendActivationLinkButtonProps extends Omit<ButtonProps, 'text'> {
  text?: React.ReactNode
}

export const ResendActivationLinkButton = ({ text, intent, ...props }: ResendActivationLinkButtonProps) => {
  const fetcher = useFetcher<ActionResponse>()
  const addToast = useToast()

  // Optional: Handle post-action effects
  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data?.success) {
      addToast({
        title: 'Sent a new activation link to your email',
        duration: 4000,
        intent: 'success'
      })
    }

    if (fetcher.state === 'idle' && fetcher.data?.success === false) {
      const message = fetcher.data.errors?.[0]?.message || 'An error occurred'
      addToast({
        title: message,
        duration: 4000,
        intent: 'danger'
      })
    }
  }, [fetcher, addToast])

  return (
    <fetcher.Form method="post" action="/api/account/resend-activation-email">
      <Button
        text={fetcher.state === 'submitting' ? 'Resending...' : text || 'Resend Activation Link'}
        intent={intent || 'semi-transparent'}
        isLoading={fetcher.state === 'submitting'}
        {...props}
      />
    </fetcher.Form>
  )
}

export default ResendActivationLinkButton
